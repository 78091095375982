<template>
    <v-card class="pa-3" max-width="800">
        <h1>{{ $t("login.title") }}</h1>
        <!-- <v-subheader>{{ $t("login.subheader") }}</v-subheader> -->
        <validation-observer
            ref="observer"
        >
            <v-form @submit.prevent="submit">
                <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    name="email"
                >
                    <div>
                        <v-text-field
                            v-model="form.email"
                            :label="$t('login.label_email')"
                            :error-messages="errors"
                            required
                            type="email" 
                            name="email" 
                            id="email"
                        ></v-text-field>
                    </div>
                </validation-provider>
                <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="password"
                >
                    <div>
                        <v-text-field
                            v-model="form.password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            :label="$t('login.label_password')"
                            :error-messages="errors"
                            required
                            name="password" 
                            id="password"
                            @click:append="showPassword = !showPassword"
                        ></v-text-field>
                    </div>
                </validation-provider>

                <vue-recaptcha 
                    ref="recaptcha"
                    :sitekey="siteKey"
                    @verify="verifyRecaptcha"
                ></vue-recaptcha>

                <div class="mt-5">
                    <v-btn color="primary" type="submit" name="submit" value="submit" class="mr-5 mb-5">{{ $t("login.label_submit") }}</v-btn>
                    <v-btn href="/wachtwoord-vergeten" class="mr-5 mb-5">{{ $t("login.link_forgot_password") }}</v-btn>
                </div>
            </v-form>
            <br>
            <v-alert
                type="error"
                v-if="feedback.show"
            >
            {{ feedback.message }}
            </v-alert>
        </validation-observer>
    </v-card>
</template>

<script>
import Vue from 'vue'
import {mapActions} from 'vuex'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode, configure } from 'vee-validate';
import { required, email, min} from 'vee-validate/dist/rules'
import { VueRecaptcha } from 'vue-recaptcha';

export default Vue.extend({
    name: "sign-in",
    components: {
        ValidationProvider,
        ValidationObserver,
        VueRecaptcha
    },
    beforeMount () {
        setInteractionMode('eager')
        extend('min', min)
        extend('required', required)
        extend('email', email)
        configure({
            defaultMessage: (field, values) => {
                values._field_ = this.$t(`login.label_${field}`);
                return this.$t(`validations.messages.${values._rule_}`, values);
            }
        });
    },
    data () {
        return ({
            siteKey:"6LdIeEUpAAAAAP8Uq1BoggdwcOB4w36LOWIX-lav",
            showPassword: false,
            form: {
                email: '',
                password: '',
                recaptchaResponse: undefined
            },
            feedback: {
                show: false,
                message: null,
            },
            errorMessages: {
                2: "Email of wachtwoord incorrect",
                3: "E-mailadres niet bevestigd",
                8: "reCaptcha incorrect",
            }
        })
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn'
        }),
        submit() {
            this.$refs.observer.validate()
            if(!this.form.email || !this.form.password || !this.form.recaptchaResponse){
                this.feedback.message = this.errorMessages[2];
                this.feedback.show = true
                return 
            } 

            const form = {
                ...this.form,
                email: this.form.email.trim()
            }

            this.signIn(form).then((response) => {
                console.log({response});
                if (this.$route.query.from){
                    this.$router.replace(this.$route.query.from);
                } else {
                    this.$router.replace({
                        name: 'gekoppeldeApparaten'
                    })
                }
            }).catch((error)=>{
                this.$refs.recaptcha.reset();
                console.warn("Failed to log in.")
                this.feedback.message = this.errorMessages[error.response.data.code];
                this.feedback.show = true;
            })
        },
        verifyRecaptcha (response) {
            this.form.recaptchaResponse = response
        }
    }
})
</script>